import OSS from 'ali-oss'
import {ossAuth} from '../api/index.js'

const ossUrl = process.env.VUE_APP_OSS_URL;

const auth = function() {
    return new Promise((resolve) => {
        ossAuth()
        .then(res => {
          if (res.code == 0) {
            res = res.data;
            resolve(res);
          } else {
            resolve({})
          }
        }).catch(err => {
          resolve({})
        });
      });
}

/**
 * 图片上传
 * 
 * @param {*} file 
 * @param {*} path
 * @returns 
 */
const upload = async function(file, path, back_path = false) {
    let ossconfig = await auth()
    let client = new OSS({
      region: ossconfig.region,
      accessKeyId: ossconfig.accessKeyId,
      accessKeySecret: ossconfig.accessKeySecret,
      stsToken: ossconfig.stsToken,
      bucket: ossconfig.bucket
    });
    return new Promise((resolve) => {
      client.put(path,file)
        .then(res => {
          if (back_path) {
            resolve(ossUrl + path + '|' + path)
          } else {
            resolve(ossUrl + path)
          }
        }).catch(err=> {
          console.log(err)
          resolve('');
        })
    });
}

export default upload